import React, { useEffect, useState } from "react";
import { httpService } from "./services/http-service";
import SlideSection from "./components/slide-section/slide-section";
import Testimonials from "./components/testimonials/testimonials";
import ContactUs from "./components/contact-us/contact-us";
import Footer from "./components/footer";
import Gallery from "./components/gallery/gallery";
import { useLocation, useNavigate } from "react-router-dom";

function App() {
  const [websiteData, setWebsiteData] = useState({});
  const [laganDate, setLaganDate] = useState('');
  const [tilakSangeetDate, setTilakSangeetDate] = useState('');
  const location = useLocation();
  const id = location.pathname.slice(1);
  useEffect(() => {
    if (id)
      httpService(`LbInvite/GetInvitationById?invitation_id=${id}`, {
        method: "GET",
      }).then(response => {
        console.log(response);
        setLaganDate(dateTimeFn(response?.lagan_date));
        setTilakSangeetDate(dateTimeFn(response?.tilak_sangeet_date));

        // localStorage.setItem(vendorKey, JSON.stringify(response));
        setWebsiteData(response);
      });
  }, []);

  function dateTimeFn(date) {
    let dateObj = new Date(date);
    let sDate = dateObj.toString().split(" ");
    return `${sDate[2]} | ${sDate[1]} | ${sDate[3]}` || '';
  }

  return (
    <div>
      <SlideSection websiteData={websiteData} />
      <div className="flex py-20 justify-around ">
        <div className="col-1">
          <img src="image/img2.png" className="w-full h-[500px]" />
        </div>
        <div className="col-1 text-xl font-semibold flex flex-col gap-2 text-center text-gray-700">
          <div className="text-2xl text-gray-400">JOIN US TO CELEBRATE THE WEDDING DAY OF</div>
          <div className="my-8" style={{ 'font-family': "Great Vibes", fontSize: '5rem' }}>
            {websiteData?.groom_name} &  {websiteData?.bride_name}
          </div>
          <div className="text-gray-400">WHICH IS CELEBRATION ON</div>
          <br />
          <div className="text-8xl">{laganDate}</div>
          <br />
          <div className="text-gray-400">STARTING</div>
          <div className="text-gray-400">IN THE AFTERNOON</div>
          <br />
          <div className="text-gray-400">{websiteData?.event_venue}</div>
          <div className="text-gray-400">{websiteData?.devine_quote}</div>
        </div>
      </div>
      <div className="h-[520px] relative h-[720px] bg-pink-200 pt-12">
        <img src="image/img5.png" className="bg-art" style={{
          transform: 'scaleY(-1)',
          right: 0,
          top: 0
        }} />
        <div className="text-center text-6xl text-orange-600">
          <div className="ft1 mt-20">Bride & Groom</div>
        </div>
        <div className="flex gap-10 justify-center text-center mt-8">
          <div></div>
          <div>
            <img src="image/img1.png" className="h-64" />
            <div className="font-semibold text-xl">{websiteData?.bride_name} </div>
            <div>D/O</div>
            <div>Grand Mother: {websiteData?.bride_grand_father_name} </div>
            <div>Grand Father: {websiteData?.bride_grand_father_name} </div>
            <div>Mother: {websiteData?.bride_mother_name} </div>
            <div>Father: {websiteData?.bride_father_name} </div>
          </div>
          <img src="image/art3.png" className="h-44 mt-16" />
          <div>
            <img src="image/img1.png" className="h-64" />
            <div className="font-semibold text-xl">{websiteData?.groom_name}</div>
            <div>D/O</div>
            <div>Grand Mother: {websiteData?.groom_grand_mother_name}  </div>
            <div>Grand Father: {websiteData?.groom_grand_father_name}  </div>
            <div>Mother: {websiteData?.groom_mother_name}  </div>
            <div>Father: {websiteData?.groom_father_name}  </div>
          </div>
          <div></div>
        </div>
        <img src="image/img5.png" className="bg-art" style={{
          transform: 'scaleX(1)',
          left: 0,
          top: 0,
          rotate: "180deg"
        }} />
      </div>
      <div className="text-center">
        <div className="m-auto text-5xl font-semibold pt-20 border-b-4 pb-2 max-w-md ft1">Event And Venues</div>
        <div className="flex justify-around h-[500px] items-center">
          <div className="box-border h-80 w-80 p-4 border-2 flex flex-col justify-center items-center gap-2">
            <img src="image/svg1.svg" className="h-28 w-28" />
            <div className="text-4xl font-semibold alex-brush-regular">Haldi & Mehndi</div>
            <div>Date {tilakSangeetDate}</div>
            <div>Venu: {websiteData?.event_venue}</div>
          </div>
          <div className="box-border h-80 w-80 p-4 border-2 flex flex-col justify-center items-center gap-2">
            <img src="image/svg2.svg" className="h-28 w-28" />
            <div className="text-4xl font-semibold alex-brush-regular">Wedding ceremony</div>
            <div>Date {laganDate}</div>
            <div>Venu: {websiteData?.event_venue}</div>
          </div>
          <div className="box-border h-80 w-80 p-4 border-2 flex flex-col justify-center items-center gap-2">
            <img src="image/svg3.svg" className="h-28 w-28" />
            <div className="text-4xl font-semibold alex-brush-regular">The Reception</div>
            <div>Date 24:04:2024</div>
            <div>Venu: KJHK KJGKJ kjhk</div>
          </div>
        </div>
      </div>
      <Gallery websiteData={websiteData} />
      <Testimonials websiteData={websiteData} />
      <ContactUs websiteData={websiteData} />
      <Footer />
    </div>
  );
}

export default App;