import React from 'react';
import Title from "shared/component/title";
import SubTitle from "shared/component/subtitle";
import InputType from "shared/component/input-type";

function ContactUs({ websiteData }) {
    return (
        <section
            id="contact"
            aria-label="Contact section"
            className=" bg-slate-200 py-12 pb-16 px-2 sm:px-12"
        >
            <div className="text-center ">
                <Title text={"Are you attending?"} />
            </div>
            <div className="flex flex-col justify-center mt-6 md:flex-row gap-4">
                <div className='flex-1'>

                    {
                        (websiteData?.address && websiteData?.city_local) &&
                        <div>
                            <span className='text-slate-900 dark:text-dark pt-5 text-md font-bold tracking-tight capitalize'>
                                Address:
                            </span>

                            <span> {`${websiteData?.address}, ${websiteData?.city_local}, ${websiteData?.district}, ${websiteData?.state}, ${websiteData?.zip_code}`}</span>
                        </div>
                    }
                    {
                        websiteData?.mobile_no &&
                        <div>
                            <span className='text-slate-900 dark:text-dark pt-5 text-md font-bold tracking-tight capitalize'>
                                Phone No:
                            </span>
                            <span> {websiteData?.mobile_no}</span>
                        </div>
                    }

                    <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14026.057966378285!2d77.40283520237743!3d28.494164611845633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce85074a4d1a3%3A0x8838a315daa9a8ef!2sChhaprauli%20Bangar%2C%203C%20Lotus%20Zing%2C%20Dostpur%20Mangrauli%2C%20Noida%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1709473347679!5m2!1sen!2sin" width="100%" height="250" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <div className="flex-1">
                    <InputType
                        type="text"
                        label="Name"
                        placeholder={"Name here ..."}
                    />
                    <InputType
                        type="text"
                        label="Phone no"
                        placeholder={"Phone here ..."}
                    />
                    <InputType
                        type="text"
                        label="Email"
                        placeholder={"Email here ..."}
                    />
                    <InputType
                        textarea={true}
                        type="text"
                        label="Message"
                        placeholder={"Message here ..."}
                    />
                    <div>
                        <button className="mt-4 bg-sky-500 hover:bg-sky-600 px-5 py-2 text-sm leading-5 rounded-full font-semibold text-white">
                            Contact now
                        </button>
                        <button className="mt-4 bg-gray-500 hover:bg-gray-600 px-5 py-2 ml-2 text-sm leading-5 rounded-full font-semibold text-white">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </section>);
}

export default ContactUs;