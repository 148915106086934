import React, { useEffect, useState } from 'react';
import Title from 'shared/component/title';
import SubTitle from 'shared/component/subtitle';
import { httpService } from 'services/http-service';
import NoData from 'shared/component/no-data';
import { getSession } from 'core/utility';
import { VendorId } from 'services/constant';
import PlaceholderLoader from 'shared/component/placeholder-loader';
import ViewImage from 'shared/component/view-image';

function Gallery({ websiteData }) {
    const [imageList, setImageList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [imgUrl, setImgUrl] = useState('');

    const viewImage = (img_path) => {
        setImgUrl(`${process.env.REACT_APP_API_BASE_URL}${img_path}`);
    }

    const closeImg = (data) => {
        setImgUrl('');
    }

    useEffect(() => {
        if (websiteData.vendor_id && getSession(VendorId)) {
            httpService(`LbVendorService/GetVendorServiceImages?vendServId=${getSession(VendorId)}`, {
                method: "GET",
            }).then(response => {
                if (Array.isArray(response)) {
                    setImageList(response);
                    setLoader(false);
                }
            });
        }
    }, [websiteData]);
    return (
        <section
            id="gallery"
            aria-label="Gallery section"
            className="bg-slate-200 py-12 pb-16 px-2 sm:px-4">
            <div className="text-center mb-8">
                <Title text={"Our Gallery"} />
            </div>
            {
                imageList.length ? (
                    <div className="flex flex-wrap justify-center gap-2.5 ">
                        {imageList?.map((value) => (
                            <div key={value?.image_id} className="relative hover:scale-105 duration-300 ease-in-out w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2 bg-white light:bg-slate-900 rounded-lg ring-1 ring-slate-900/5 shadow-xl text-left">
                                <img className="rounded object-cover h-48 w-full" src={`https://dev-lb-api.kiatree.com/${value.img_path}`} alt="no Gallery image" />
                                <span onClick={()=>viewImage(value.img_path)} className='absolute bottom-1 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 text-white/50 hover:text-white  cursor-pointer">
                                        <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                        <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clipRule="evenodd" />
                                    </svg>
                                </span>
                            </div>
                        ))}
                    </div>
                ) : (
                    loader ? <PlaceholderLoader className="m-4" numberOfCard={4} /> :
                    imageList.length === 0 ? <NoData text="No image is listed by the company" /> : ''
                    
                    )
                }
                {imgUrl ? <ViewImage imageUrl={imgUrl} closeImg={closeImg}/> : ''}
        </section>
    );
}

export default Gallery;