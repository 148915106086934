import React from 'react';

const Title = ({ text }) => {
    return (
        <div className="dark:text-dark pt-5 text-6xl font-semibold tracking-tight capitalize ft1 text-orange-600">
            {text ? text : ""}
        </div>
    );
};

export default Title;