import React, { Suspense, lazy } from 'react';
import PlaceholderLoader from 'shared/component/placeholder-loader';
const Slide = lazy(() => import('./slide'))
export default function SlideSection({ websiteData }) {
    return (
        <Suspense fallback={<PlaceholderLoader className="m-4" />}>
            <Slide websiteData={websiteData} />
        </Suspense>
    );
}
