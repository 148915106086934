import React, { useEffect, useState } from 'react';
import Title from 'shared/component/title';
import SubTitle from 'shared/component/subtitle';
import TestimonialCard from 'shared/component/testimonial-card';
import { httpService } from 'services/http-service';
import NoData from 'shared/component/no-data';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PlaceholderLoader from 'shared/component/placeholder-loader';

function Testimonials({ websiteData }) {
    const [reviewList, setReviewList] = useState([]);
    const [loader, setLoader] = useState(true);
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        if (websiteData?.vendor_id) {
            httpService(`LbVendor/GetVendorReviews?vendorId=${websiteData?.vendor_id}`, {
                method: "GET",
            }).then(response => {
                if (Array.isArray(response)) {
                    setReviewList(response);
                    setLoader(false);
                }
            });
        }
    }, [websiteData]);
    return (

        <section
            id="testimonials"
            aria-label="Testimonials section"
            className="container mx-auto my-12"
        >
            <div className="text-center">
                <Title text={"Sweet Messages"} />
            </div>
            {
                reviewList.length ? (
                    <div className='px-0 md:px-8'>
                        <Slider {...settings}>
                            {reviewList?.map((value, index) => (
                                <div className='px-3 my-12' key={index}>
                                    <TestimonialCard
                                        comment={value?.message}
                                        name={value?.cust_name}
                                        place={value?.created_at.split('T')[0]}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                ) : (
                    loader ? <PlaceholderLoader className="m-4" numberOfCard={4}/> :
                        reviewList.length === 0 ? <NoData text="No review is available" /> : ''
                )
            }

        </section>
    );
}

export default Testimonials;